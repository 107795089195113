export const reportTableKeys = ["report", "serial", "createdAt", "leadId", "name", "phone", "email", "status", "reasonReject", "policyTypeId.name", "complaintTypeId.name", "insuranceCompanyId.name", "claimAmount", "policyNumber", "complaintCreatedBy.name"];
export const reportTableHeading = ["S No.", "Date", "ID", "Name", "Phone", "Email", "Status", "Rejected Reason", "Policy Type", "Complaint Type", "Insurance Company", "Claim Amount", "Policy Number", "Uploaded By"];

export const settledTableReportHeading = ["S No.", "Settled Date", "Name", "Phone", "Email", "Status", "Policy Type", "Complaint Type", "Insurance Company", "Claim Amount", "Settled Amount", "Policy Number", "Total Commission", "TDS Amount", "Final Payment Amount", "Uploaded By"];
export const settledTableReportKeys = ["report", "serial", "statusHistory", "name", "phone", "email", "status", "policyTypeId.name", "complaintTypeId.name", "insuranceCompanyId.name", "claimAmount", "approvedClaimAmount", "policyNumber", "commisionAmount", "tdsAmount", "finalPaymentAmount", "partnerName"];

export const userReportHeading = ["S No.", "Name", "Email", "Date", "Mobile", "Hierarchical Level", "Contract Signed"];
export const userReportTableKeys = ["user", "serial", "name", "email", "createdAt", "mobile", "hierarchicalLevel", "agreedOn"];

export const activeUserReportHeading = ["S No", "Name", "Mobile", "Email", "Last Active Date", "Last Login Date", "Total leads uploaded in last 1 month"];
export const activeUserReportTableKeys = ["activeUser", "serial", "name", "mobile", "email", "lastActive", "lastLogin", "leadCount"];

export const uploadHistoryHeading = ["Creative name", "Category", "Date"];
export const uploadHistoryKeys = ["gallery", "name", "category", "createdAt"];

export const leadHeading = ["lead", "Actions", "Date", "ID", "Name", "Mobile", "Status", "Email", "Policy Type", "Complaint Type", "Insurance Company", "Claim Amount", "Assign To", "Expert To", "SRC | MED | CPN"];
export const leadKeys = ["lead", "action", "createdAt", "leadId", "name", "phone", "status", "email", "policyTypeId.name", "complaintTypeId.name", "companyId.name", "claim_amount", "assign_to", "assign_to_expert", "src"];
export const sortKeysLead = ["Date", "ID", "Name", "Policy Type", "Complaint Type", "Insurance Company", "Claim Amount"];

export const resolveCaseHeading = ["S.No", "Name", "Mobile", "Source", "Hierarchy", "Resolved Date", "Resolution Amount", "Commission Amount"];
export const resolveCaseKeys = ["resolve", "count", "name", "mobile", "partnerName", "hierarchy", "resolutionDate", "claimAmount", "commission"];

export const complaintHeading = ["complaints", "Actions", "Policy Number", "Name", "Mobile", "Email", "Status", "Insurance Company", "Claim Amount", "Complaint Type", "Policy Type", "SRC | MED | CPN"];
export const complaintKeys = ["complaints", "action", "policyNumber", "name", "phone", "email", "status", "insuranceCompanyId.name", "claimAmount", "complaintTypeId.name", "policyTypeId.name", "src"];
export const sortKeysComplaint = ["Policy Number", "Name", "Status", "Claim Amount", "Policy Type", "Complaint Type", "Insurance Company", "Claim Amount"];

export const allUserHeading = ["allUsers", "Name", "Email", "Mobile", "Date", "Hierarchy", "Contract Signed", "Action"];
export const allUserKeys = ["allUsers", "name", "email", "mobile", "createdAt", "hierarchicalLevel", "contractHistory", "action"];
export const sortAllUserKeys = ["Date"];

export const leadInvoicingHeading = ["Mark for Invoicing", "Update", "Name", "Lead ID", "Policy Number", "Status", "Claim Amount"];
export const leadInvoicingKeys = ["allUsers", "name", "email", "mobile", "createdAt", "hierarchicalLevel", "contractHistory", "action"];

// export const initUserHeading = ["initUsers", "Name", "Email", "Mobile", "Referral Code", "User Status", "Actions", "Complete Profile"];
// export const initUserKeys = ["initUsers", "name", "email", "mobile", "referredBy", "isActive", "action-one", "action-two"];

export const settledCaseHeading = ["S. No.", "Name", "Policy Number", "Final Amt to be Paid", "Invoice Date	", "Settled At"];
export const settledCaseKeys = ["settledCase", "serial", "name", "policyNumber", "finalAmountToBePaid", "invoiceRaisedDate", "settledDate"];

export const resendCaseHeading = ["S. No.", "Name", "Policy Number", "Count", "Latest Date"];
export const resendCaseKeys = ["resendCase", "serial", "name", "policyNumber", "statusCount", "latestDate"];

export const companyHeading = ["companyTable", "Name", "Policy Type", "Address", "Company Email", "Company Level1 Email", "Action", "Status"];
export const companyKeys = ["companyTable", "name", "policyTypeId.name", "address", "companyEmail", "companyLevel1Email", "action", "isActive"];

export const partnerMappingHeadings = ["partnerExecutiveMapping", "Partner Name ", "Selected Executives", "INSA Executives", "Action"];

export const generateInvoiceHeading = ["generateInvoiceHeading", "Mark for Invoicing", "Update", "Name", "Lead ID", "Policy Number", "Status", "Claim Amount", "Compensation Rate", "Mobile Number", "Email"];
export const generateInvoiceKeys = ["sumit"]; //Currently unknown
export const partnerMappingKeys = ["partnerExecutiveMapping", "name", "mappedInSaUsersDetails", "executives", "action"];
export const initUserHeading = ["initUsers", "Name", "Email", "Mobile", "Referral Code", "User Status", "Actions", "Complete Profile"];
export const initUserKeys = ["initUsers", "name", "email", "mobile", "referredBy", "isActive", "action-one", "action-two"];
// export const partnerMappingHeadings = ["partnerExecutiveMapping", "Partner Name ", "Selected Executives", "INSA Executives", "Action"];
// export const partnerMappingKeys = ["partnerExecutiveMapping", "name", "selectedExecutives", "executives", "action"];
